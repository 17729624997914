import React from "react"

const CaretLeft = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 1.15625L4 7.28958" stroke="currentColor" />
    <path d="M10 12.8438L4 6.71042" stroke="currentColor" />
  </svg>
)

export default CaretLeft
