import { Box, Flex } from "@theme-ui/components"
import { useResponsiveValue } from "@theme-ui/match-media"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"
import { useThemeUI } from "theme-ui"
import Logo from "../assets/logo.svg"
import FeaturedCaseMobile from "../components/featured-case-mobile"
import FeaturedCases from "../components/featured-case-nav"
import FeaturedColors from "../components/featured-colors"
import Footer from "../components/footer"
import Layout from "../components/layout"
import NavLink from "../components/nav-link"
import Player from "../components/player"
import SEO from "../components/seo"
import { InterfaceContext } from "../context/interface"

const RootIndex = ({ data, location, pageContext }) => {
  const { colorMode, setColorMode } = useThemeUI()
  useEffect(() => {
    setColorMode("default")
  }, [])

  const cases = data.featured.cases
  const { mainNav, footerNavigation } = data

  const [activeIndex, setActiveIndex] = useState(0)
  const [activeCase, setActiveCase] = useState(cases[0])
  const isVideoCase = activeCase.vimeoId ? true : false

  const { locale, translationLinks, setTranslationLinks } =
    useContext(InterfaceContext)

  useEffect(() => {
    setTranslationLinks({
      "en-US": "",
      "sv-SE": "",
      "da-DK": "",
    })
  }, [])

  const next = () => {
    if (activeIndex === cases.length - 1) {
      setActiveIndex(0)
      setActiveCase(cases[0])
    } else {
      const nextIdx = activeIndex + 1
      setActiveIndex(activeIndex + 1)
      setActiveCase(cases[nextIdx])
    }
  }

  const [lang] = pageContext.locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }
  let now = new Date()

  const showNav = useResponsiveValue([false, true])
  return (
    <Layout
      nav={data.navigation}
      subNav={data.subnav}
      prefix={prefix}
      shouldShowNav={showNav}
      location={location}
      locale={pageContext.locale}
      renderAboveFold={() => {
        return (
          <Box
            pt={["0", "120px"]}
            sx={{
              color: "#fff",
              lineHeight: "25px",
              fontSize: [2, "20px"],
              fontWeight: 200,
              fontFamily: "pragmatica-extended",
              maxWidth: "750px",
            }}
          >
            <Box
              sx={{ whiteSpace: "pre-wrap" }}
              className="rte rte-h1-inline home-above-fold"
              dangerouslySetInnerHTML={{
                __html: data.intro.text.childMarkdownRemark.html,
              }}
            />
          </Box>
        )
      }}
    >
      <SEO locale={pageContext.locale} title={"Home"} />
      <Box
        sx={{
          display: ["none", "flex"],
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {isVideoCase && (
            <Player
              showPlay
              id={activeCase.slug}
              thumbnail={activeCase.thumbnail}
              aspectRatio={"2:1"}
              vimeoId={activeCase.vimeoId}
            />
          )}
          {!isVideoCase && activeCase.mainContent?.file?.url && (
            <GatsbyImage
              style={{
                aspectRatio: "2/1",
              }}
              image={activeCase.mainContent?.gatsbyImageData}
            />
          )}
        </Box>
        <Flex
          sx={{
            transition: "background-color 500ms",
            backgroundColor: activeCase.backgroundColor,
            alignItems: "center",
            flex: "1",
          }}
          px={"80px"}
          py={"40px"}
        >
          <Flex
            sx={{
              position: "relative",
              alignItems: "center",
              zIndex: "110",
              width: "calc(100% + 150px)",
            }}
          >
            <Box pt={"28px"} sx={{ flex: 1 }}>
              <Box
                sx={{
                  fontFamily: "pragmatica-extended, sans-serif",
                  a: { textDecoration: "none" },
                }}
                mb={20}
              >
                <Link to={`${prefix}cases/${activeCase.slug}/`}>
                  <Box
                    mb={"4px"}
                    sx={{
                      fontWeight: "200",
                      fontSize: "13px",
                      lineHeight: 1.25,
                      textTransform: "uppercase",
                    }}
                  >
                    Case
                  </Box>
                  <Box
                    mb={"5px"}
                    sx={{
                      fontSize: "13px",
                      lineHeight: 1.25,
                      transition: "all 200ms",
                      textTransform: "uppercase",
                      fontWeight: "400",
                    }}
                  >
                    {activeCase.clientName}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Formular, sans-serif",
                      fontSize: "10px",
                    }}
                  >
                    {activeCase.title}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Formular, sans-serif",
                      fontSize: "10px",
                    }}
                  >
                    {activeCase.caseType}
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box
                sx={{
                  maxWidth: 250,
                  width: "100%",
                  height: "100%",
                  marginBottom: "15px",
                }}
              >
                <FeaturedColors
                  color1={activeCase.color1}
                  color2={activeCase.color2}
                  color3={activeCase.color3}
                />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  fontSize: 3,
                  fontWeight: "300",
                  display: "inline-block",
                  fontFamily: "pragmatica-extended",
                  textAlign: "right",
                  textTransform: "uppercase",
                }}
                onClick={() => next()}
              >
                Next
              </Box>
              <Box
                sx={{
                  fontWeight: "400",
                  fontFamily: "pragmatica-extended",
                  textAlign: "right",
                  fontSize: 1,
                }}
              >
                ({activeIndex + 1}/{cases.length})
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          display: ["flex", "none"],
          minHeight: "100vh",
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            flexGrow: 1,
            backgroundColor: "white",
          }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "space-between",
              gap: 4,
            }}
          >
            <Flex
              sx={{
                flexGrow: [1, 0],
                flexDirection: "column",
                justifyContent: ["flex-end", "unset"]
              }}
              px={4}
              pb={[2, 0]}
            >
              <Box sx={{ width: ["80px", "95px"] }} pt={"50px"}>
                <Link to={prefix}>
                  <img src={Logo} />
                </Link>
              </Box>
            </Flex>
            <Flex
              px={4}
              pb={[0, 30]}
              sx={{
                flexDirection: "column",
                flex: [0, "1"],
                justifyContent: "center",
              }}
            >
              <NavLink
                sx={{
                  a: {
                    fontSize: ["7px", 1],
                  },
                }}
                small
                to={prefix}
              >
                Home
              </NavLink>
              {mainNav.items.map((i) => {
                if (i.internal?.type === "ContentfulFeaturedCases") {
                  return (
                    <FeaturedCases
                      sx={{
                        a: {
                          fontSize: ["19px", 3],
                        },
                      }}
                      key={i.id}
                      item={i}
                      location={location}
                      prefix={prefix}
                      mb={1}
                    />
                  )
                }
                if (i.internal.type === "ContentfulNavigationItem") {
                  return (
                    <NavLink
                      sx={{
                        a: {
                          fontSize: ["19px", 3],
                        },
                      }}
                      mb={1}
                      key={i.id}
                      to={`${prefix}${i.link.slug}/`}
                    >
                      {i.title}
                    </NavLink>
                  )
                }

                return (
                  <NavLink
                    sx={{
                      a: {
                        fontSize: ["19px", 3],
                      },
                    }}
                    mb={1}
                    key={i.id}
                    to={`${prefix}${i.slug}/`}
                  >
                    {i.title}
                  </NavLink>
                )
              })}
            </Flex>
            {cases && (
              <FeaturedCaseMobile featuredCase={cases[0]} prefix={prefix} />
            )}
          </Flex>
        </Flex>
        <Footer
          sx={{ display: ["flex", "hidden"] }}
          nav={footerNavigation}
          prefix={prefix}
        />
      </Flex>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery($locale: String!) {
    intro: contentfulTextColumn(
      node_locale: { eq: $locale }
      name: { eq: "Frontpage" }
    ) {
      text {
        text
        childMarkdownRemark {
          html
        }
      }
    }
    footerNavigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...navigationFields
    }
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    featured: contentfulFrontPageCases(node_locale: { eq: $locale }) {
      node_locale
      title
      cases {
        ...casePreviewFields
      }
    }
    mainNav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      node_locale
      ...navigationFields
    }
  }
`
