import { Box, Flex } from "@theme-ui/components"
import React from "react"

export default ({ color1, color2, color3, ...rest }) => {
  const styles = {
    transition: "background-color 200ms",
    flex: 1,
    marginLeft: "2px",
    height: "3px",
  }

  return (
    <Flex {...rest}>
      <Box sx={{ ...styles, backgroundColor: color1 }} />
      <Box sx={{ ...styles, backgroundColor: color2 }} />
      <Box sx={{ ...styles, backgroundColor: color3 }} />
    </Flex>
  )
}
