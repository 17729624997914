import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Flex } from "theme-ui"
import FeaturedColors from "./featured-colors"

const FeaturedCaseMobile = ({ featuredCase, sx }) => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      to={`/cases/${featuredCase.slug}/`}
    >
      <Box
        sx={{
          textTransform: "uppercase",
          flexDirection: "column",
          fontFamily: "pragmatica-extended",
          fontWeight: 300,
          ...sx,
        }}
      >
        {featuredCase.thumbnail?.gatsbyImageData && (
          <Box
            sx={{
              aspectRatio: "3/2",
            }}
          >
            <GatsbyImage
              width="100%"
              height="100%"
              style={{ height: "100%", objectFit: "cover" }}
              image={featuredCase.thumbnail.gatsbyImageData}
              alt=""
            />
          </Box>
        )}
        <Flex
          p={4}
          sx={{
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Box
            sx={{
              display: ["block", "none"],
              fontSize: "14px",
              fontWeight: 300,
              color: "#D8D8D8",
            }}
          >
            Featured case
          </Box>
          <FeaturedColors
            sx={{ width: "50%" }}
            color1={featuredCase.color1}
            color2={featuredCase.color2}
            color3={featuredCase.color3}
          />
          <Flex
            sx={{
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: 400
              }}
            >
              {featuredCase.clientName}
            </Box>
            <Box
              sx={{
                fontSize: "10px",
                fontFamily: "Formular, sans-serif",
              }}
            >
              {featuredCase.title}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Link>
  )
}

export default FeaturedCaseMobile
