import { Box, Flex } from "@theme-ui/components"
import { Link } from "gatsby"
import React from "react"

import CaretLeft from "./svg/caret-left"
import Logo from "./svg/logo"

const Footer = ({ nav, prefix, px, pb, sx, backLink }) => {
  return (
    <Flex
      pt={["40px", "150px"]}
      pb={pb ?? ["40px", "100px"]}
      px={px ?? [4, "40px"]}
      mx="auto"
      sx={{
        width: "100%",
        maxWidth: "1200px",
        fontFamily: "pragmatica-extended",
        a: {
          textDecoration: "none",
          textTransform: "uppercase",
        },
        fontSize: ["7px", "8px"],
        fontWeight: 300,
        lineHeight: 1.5,
        textTransform: "uppercase",
        justifyContent: "space-between",
        alignItems: "flex-start",
        color: "darkest",
        ...sx,
      }}
    >
      <Flex
        sx={{
          flexDirection: ["column"],
          gap: "20px",
        }}
      >
        <Flex
          sx={{
            a: {
              fontWeight: ["400"],
              fontSize: ["9px", 1],
            },
            gap: "20px",
          }}
        >
          {backLink && (
            <Flex
              sx={{
                display: ["none !important", "flex !important"],
                fontWeight: "400",
                fontSize: 1,
                color: "#2A2C3",
                alignItems: "center",
              }}
            >
              <Link
                to={backLink}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "4px",
                  position: "relative",
                }}
              >
                <Flex
                  sx={{
                    position: "absolute",
                    left: -14,
                    alignItems: "center",
                  }}
                >
                  <CaretLeft />
                </Flex>
                Back
              </Link>
            </Flex>
          )}
          {nav.items.map((navItem, idx) => (
            <Link key={idx} to={`${prefix}${navItem.slug}/`}>
              {navItem.title}
            </Link>
          ))}
        </Flex>
        <Flex
          sx={{
            gap: "20px",
          }}
        >
          <Box>
            <Box>Copyright © {new Date().getFullYear()}</Box>
            <Box>All Rights Reserved</Box>
          </Box>
          <Box>
            <Box>
              <a href="https://instagram.com/blonde___inc">Instagram</a>
              {" / "}
              <a href="https://vimeo.com/blondeinc">Vimeo</a>
              {" / "}
              <a href="https://linkedin.com/company/blondeinc">LinkedIn</a>
            </Box>
            <Box>
              <Link to={`${prefix}privacy/`}>Privacy Policy</Link>
            </Box>
          </Box>
        </Flex>
      </Flex>
      <Box
        sx={{
          display: ["none", "block"],
        }}
      >
        <Logo width={124} />
      </Box>
    </Flex>
  )
}
export default Footer
